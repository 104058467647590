.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2d54de;
  border-radius: 10px;
  border: 0;
  box-shadow: rgba(1, 60, 136, 0.5) 0 -1px 3px 0 inset,
    rgba(0, 44, 97, 0.1) 0 3px 6px 0;
  cursor: pointer;
  display: inherit;
  font-family: "gilroy-semibold";
  font-size: 17px;
  line-height: 32px;
  padding: 14px 20px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all 0.2s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.button:hover {
  background-color: #2544b4;
  transform: translateY(-2px);
}

@media (max-width: 1250px) {
  .button {
    padding: 10px 16px;
    font-size: 14px;
  }
}

@media (max-width: 650px) {
}
