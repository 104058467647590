.linker-button {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: 50px;

    font-size: 20px;
    font-family: 'gilroy-bold';
    letter-spacing: -0.6px;
    line-height: 32px;
    color: #2D54DE;

    transition: all .25s ease-out;
    border-bottom: 1px solid #fff;
}

.linker-button:hover {
    border-bottom: 1px solid #2D54DE;
}

.linker-button .arrow-linker {
    height: 18px;
    width: 18px;
    padding-top: 3px;
    margin-left: 9px;
    transition: all .10s ease-out;
}

.linker-button:hover .arrow-linker {
    transform: translateX(1px);
    transform: translateY(-2px);
    margin-left: 12px;
}