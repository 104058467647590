#CardPrice {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    padding: 15px 20px;
    width: 100%;
  
    background: #fff;
    border: 1px solid #e7e9ed;
    border-top: 10px solid #161C2D;
    border-radius: 10px;
    overflow: hidden;
}

#CardPrice .price-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  
    padding: 15px 0 0 0;
}