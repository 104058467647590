.section-banner {
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  width: 100%;
  height: auto;

  overflow: hidden;
  max-width: 1920px;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;

  background: #161c2d;
}

.section-banner .banner-content {
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;

  text-align: center;
}

.section-banner .pattern-image {
  height: 100%;
}

@media (max-width: 850px) {
  .section-banner {
    height: 500px;
  }
}