.cta-button {
  background-color: #2D54DE;
  border-radius: 10px;
  border: 0;
  box-shadow: rgba(1,60,136,.5) 0 -1px 3px 0 inset,rgba(0,44,97,.1) 0 3px 6px 0;
  cursor: pointer;
  display: inherit;
  font-family: 'gilroy-semibold';
  font-size: 17px;
  line-height: 32px;
  padding: 14px 20px;
  position: relative;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  transition: all .2s cubic-bezier(.22, .61, .36, 1);
}

.cta-button:hover {
  background-color: #2544b4;
  transform: translateY(-2px);
}

#HeadBanner .cta-button:hover .arrow-cta {
  transition: all .2s cubic-bezier(.22, .61, .36, 1);
  transform: translateX(1px);
}

@media (max-width: 1250px) {
  .cta-button {
    padding: 10px 16px;
    font-size: 14px;
  }
}

@media (max-width: 650px) {
}
