.cours-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 60%;
}

.cours-container .cours-divider {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 50px 0;
}

.cours-container .cours-left {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 67.5%;
}

.cours-container .cours-right {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
}

.cours-container .cours-divider ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;

  width: 100%;
  padding: 25px 0;
}

.cours-container .cours-divider ul li {
  position: relative;
  display: flex;
  flex-direction: column;

  padding: 15px 20px;
  width: 100%;

  background: #fff;
  border: 1px solid #e7e9ed;
  border-radius: 10px;
  overflow: hidden;
}

.cours-container ul li p {
  z-index: 5;
}

.cours-container ul li span.t {
  z-index: 5;
  font-size: 18px;
  margin-bottom: 25px;
  padding-left: 5px;
  border-left: 3px solid;
}

.cours-container #fill {
  position: absolute;
  top: 10px;
  left: -50px;
  width: 20%;
  aspect-ratio: 1 / 1;
  z-index: -1;
}

.cours-container .cours-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 500px;
  object-fit: cover;
}

.cours-container .cours-image {
  margin: 25px 0;
  z-index: 1;
  border-radius: 10px !important;
  width: 100%;
  min-height: 500px;
  object-fit: cover;
}

#hours-container #hours-list {
  margin-top: 25px;
}

#hours-container #hours-list .list-item {
  margin-bottom: 10px;
  padding-left: 25px;
}

#square-container {
  position: relative;
  display: flex;
  justify-content: center; /* Space between the squares */
  align-items: center;
  overflow: hidden;

  width: 100%;
  min-height: 500px;
}

#square-container .square {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}

#square-container .square:first-child {
  z-index: 5;
  position: absolute;
  margin-bottom: 150px;
  margin-right: 150px;
}

#square-container .square:last-child {
  margin-top: 150px;
  margin-left: 150px;
  position: absolute;
}

#square-container .square-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 300px;
  overflow: hidden;
  border-radius: 10px;
}

#square-container .square-content img.mas {
  height: 350px;
  object-fit: cover;
  object-position: 0 20px;
}

#square-container .square-content img.donat {
  margin-top: 160px;
  object-fit: cover;
  width: 320px;
}

.link-cours:hover {
  text-decoration: underline;
}

#download-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 1250px) {
  .cours-container {
    width: 75%;
  }

  .cours-container .cours-divider {
    flex-direction: column-reverse;
  }

  .cours-container .cours-left {
    width: 100%;
  }

  .cours-container .cours-right {
    width: 100%;
  }

  .cours-container ul {
    flex-direction: row;
    gap: 10px;
  }
}

@media (max-width: 650px) {
  .cours-container {
    width: 90%;
  }

  .cours-container #fill {
    top: 0px;
    left: -40px;
    width: 30%;
  }

  #square-container .square-content {
    width: 200px;
    height: 200px;
  }
}
